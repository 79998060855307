import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { StyledButtonContainer } from "./StyledMuiComponents";
import PropTypes from "prop-types";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.buttonText};
  background: ${(props) =>
    props.selected
      ? props.theme.selectedButtonBackground
      : props.theme.unselectedButtonBackground};
  box-shadow: ${(props) => props.theme.boxShadowColor};
`;

const MuiButton = ({ label, size, onClick, selected }) => {
  return (
    <StyledButtonContainer>
      <StyledButton size={size} onClick={onClick} selected={selected}>
        <strong>{label}</strong>
      </StyledButton>
    </StyledButtonContainer>
  );
};

MuiButton.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  unselected: PropTypes.bool,
};

export default MuiButton;
