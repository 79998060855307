import React, { useState, useEffect, useRef } from "react";
import { StyledChainError } from "../Styles/styledElements";
import axios from "axios";
import MuiCard from "../Styles/MuiComponents/MuiCard";
import MapDataPoints from "../DataPoints/MapDataPoints";
import MapCardHeader from "../DataPoints/MapCardHeader";

const ResearchChainData = ({ submittedText }) => {
  const isMounted = useRef(false);
  const [chainData, setChainData] = useState([]);
  const [chainError, setChainError] = useState([]);

  useEffect(() => {
    if (isMounted.current) {
      setChainError([]);
      axios
        .get(
          `https://api.tdameritrade.com/v1/marketdata/chains?apikey=${process.env.REACT_APP_GITHUB_CLIENT_ID}&symbol=${submittedText}&contractType=ALL&strikeCount=2&includeQuotes=TRUE&toDate=${process.env.REACT_APP_DATE}&range=OTM`
        )
        .then((response) => {
          response.data.status === "FAILED"
            ? setChainError("error")
            : setChainData([response.data]);
        });
    } else {
      isMounted.current = true;
    }
  }, [submittedText]);

  return (
    <div>
      {chainError === "error" ? (
        <StyledChainError>
          No option chain data for {submittedText}
        </StyledChainError>
      ) : !!chainData.length ? (
        chainData.map((option) => (
          <MuiCard
            key={option.symbol}
            content={
              <>
                {" "}
                <MapCardHeader option={option} />
                <MapDataPoints option={option} mapType={"call"} />
              </>
            }
          />
        ))
      ) : (
        ""
      )}
    </div>
  );
};

export default ResearchChainData;
