import React, { useState } from "react";
import {
  OuterNavContainer,
  NavbarLink,
  StyledNavbar,
} from "../Styles/styledElements";
import Search from "../Search/Search";
import LightDarkToggle from "./LightDarkToggle";
import { Button } from "@material-ui/core";
import { FaUserAstronaut } from "react-icons/fa";
import MainLogo from "./MainLogo";

const BasicNav = ({ lightDarkChange }) => {
  const [activeSearchBar, setActiveSearchBar] = useState(false);

  const focusHandler = () => {
    setActiveSearchBar(true);
  };

  return (
    <OuterNavContainer>
      <StyledNavbar>
        <Button
          startIcon={
            <NavbarLink to="/">
              <MainLogo />
            </NavbarLink>
          }
          size="medium"
        ></Button>
        <LightDarkToggle lightDarkChange={lightDarkChange} />
        <Button
          startIcon={
            <NavbarLink to="/profile">
              <FaUserAstronaut />
            </NavbarLink>
          }
          size="medium"
        ></Button>
      </StyledNavbar>

      <Search focusHandler={focusHandler} activeSearchBar={activeSearchBar} />
    </OuterNavContainer>
  );
};

export default BasicNav;
