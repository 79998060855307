import React, { useState, useEffect } from "react";
import {
  useRecoilState,
  //useRecoilValue
} from "recoil";
import MuiButton from "../Styles/MuiComponents/MuiButton";
import MuiCard from "../Styles/MuiComponents/MuiCard";
import { sectorStocks } from "../Api/Urls";
import { dataDisplayState } from "../../recoil/atoms/globalState";
import { SectorHeader } from "../Styles/styledElements";
import axios from "axios";
import SectorItemsMenu from "./SectorItemsMenu";
import DataGridDisplay from "../DataGrid/DataGridDisplay";
import MapCardHeader from "../DataPoints/MapCardHeader";
import MapDataPoints from "../DataPoints/MapDataPoints";
import GridToggle from "../Styles/MuiComponents/GridToggle";
import LoadingSpinner from "../Styles/Animations/LoadingSpinner";

let symbolArray = [];

function SectorStocks() {
  const [dataArray, setDataArray] = useState([]);
  const [sectorSelected, setSectorSelected] = useState("Finance");
  const [optionType, setOptionType] = useState("call");
  const [showGridData, setShowGridData] = useRecoilState(dataDisplayState);

  const toggleGrid = () => setShowGridData(!showGridData);

  const buttonHandlerPut = () => {
    setOptionType("put");
  };

  const buttonHandlerCall = () => {
    setOptionType("call");
  };

  console.log(sectorSelected);
  const selectSector = (selection) => {
    setSectorSelected(selection);
  };

  let sectorError = [];
  switch (sectorSelected) {
    case "Tech":
      symbolArray = [
        "AMD",
        "SONY",
        "ZM",
        "AAPL",
        "MSFT",
        "AMZN",
        "ORCL",
        "GOOGL",
        "NFLX",
        "NVDA",
        "PLTR",
        "SNOW",
      ];
      break;
    case "Entertainment":
      symbolArray = ["AMC", "ATVI", "DIS", "MGM", "WYNN"];
      break;
    case "Airlines":
      symbolArray = ["ALGT", "DAL", "LUV", "ALK", "UAL", "AAL", "SAVE", "BA"];
      break;
    case "Finance":
      symbolArray = ["AXP", "BAC", "C", "JPM", "WFC"];
      break;
    case "Oil":
      symbolArray = ["PXD", "COP", "MPC", "OXY", "CVX", "XOM", "BP"];
      break;
    case "Cannabis":
      symbolArray = ["CRON", "ACB", "TLRY", "HEXO", "SNDL", "IGC", "OGI"];
      break;
    case "Pharmaceutics":
      symbolArray = ["JNJ", "PFE", "MRNA", "AZN", "AMGN", "BNTX", "SGEN"];
      break;
    case "Alternative Energy":
      symbolArray = ["NEE", "FSLR", "SEDG", "PLUG", "BLNK", "ENPH", "SPWR"];
      break;
    case "Automotive":
      symbolArray = ["HYLN", "GM", "NIO", "CVNA", "F", "TSLA", "RIDE", "WKHS"];
      break;
    case "Grocery":
      symbolArray = ["WMT", "ACI", "COST", "KR", "GO", "BJ", "TGT"];
      break;
    case "Crypto":
      symbolArray = ["MARA", "RIOT", "BTCM", "BITF", "BITQ", "HUT", "COIN"];
      break;
    case "Social Media":
      symbolArray = ["PINS", "TWTR", "FB", "SNAP"];
      break;
    default:
      sectorError = `No data to display for ${sectorSelected}.`;
  }

  useEffect(() => {
    const chainData = [];
    symbolArray.map((symbol) =>
      axios.get(sectorStocks(symbol)).then((response) => {
        console.log("sectors", response.data);
        chainData.push(response.data);
        setDataArray([chainData]);
      })
    );
  }, [sectorSelected]);

  return (
    <>
      <SectorItemsMenu
        sectorSelector={selectSector}
        sectorSelected={sectorSelected}
      />
      {!!sectorError.length ? (
        <SectorHeader>{sectorError}</SectorHeader>
      ) : (
        <SectorHeader>{sectorSelected}</SectorHeader>
      )}
      <MuiButton
        label={"Call"}
        size={"small"}
        onClick={buttonHandlerCall}
        selected={optionType === "call"}
      />
      <MuiButton
        label={"Put"}
        size={"small"}
        onClick={buttonHandlerPut}
        selected={optionType === "put"}
      />

      <br></br>
      <GridToggle showGridData={showGridData} _onClick={toggleGrid} />

      {!!dataArray.length ? (
        showGridData && optionType === "call" ? (
          <DataGridDisplay
            option={
              !!dataArray.length
                ? dataArray.map((stock) => stock.map((option) => option))
                : " "
            }
            mapType={"call"}
          />
        ) : (
          ""
        )
      ) : (
        " "
      )}
      {!!dataArray.length ? (
        dataArray.map((stock) =>
          stock.map(
            (option) =>
              !showGridData && (
                <MuiCard
                  key={option.symbol}
                  hidden={optionType === "put"}
                  content={
                    <>
                      <MapCardHeader option={option} />

                      <MapDataPoints option={option} mapType={"call"} />
                    </>
                  }
                />
              )
          )
        )
      ) : (
        <LoadingSpinner />
      )}
      {!!dataArray.length ? (
        showGridData && optionType === "put" ? (
          <DataGridDisplay
            option={
              !!dataArray.length
                ? dataArray.map((stock) => stock.map((option) => option))
                : " "
            }
            mapType={"put"}
          />
        ) : (
          ""
        )
      ) : (
        " "
      )}
      {!!dataArray.length &&
        dataArray.map((stock) =>
          stock.map(
            (option) =>
              !showGridData && (
                <MuiCard
                  key={option.symbol}
                  hidden={optionType === "call"}
                  content={
                    <>
                      <MapCardHeader option={option} />

                      <MapDataPoints option={option} mapType={"put"} />
                    </>
                  }
                />
              )
          )
        )}
    </>
  );
}

export default SectorStocks;
