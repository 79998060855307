const {
  atom,
  //selector,
  //useCallBack
} = require("recoil");

export const dataDisplayState = atom({
  key: "dataDisplayState",
  default: false,
});

export const addedWatchlistOptionsState = atom({
  key: "addedWatchlistOptionsState",
  default: null,
  dangerouslyAllowMutability: true,
});

export const rightDrawerOpenState = atom({
  key: "rightDrawerOpenState",
  default: false,
});

export const rightDrawerContentState = atom({
  key: "rightDrawerContentState",
  default: "",
});
