import React from "react";
import {
  StyledKpiItem,
  StyledKpiValue,
  StyledKpiDescription,
} from "../Styles/styledElements";

const KpiValue = ({ description, value }) => {
  return (
    <StyledKpiItem>
      <StyledKpiValue>{value}</StyledKpiValue>
      <StyledKpiDescription>{description}</StyledKpiDescription>
    </StyledKpiItem>
  );
};

export default KpiValue;
