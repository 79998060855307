import React from "react";
import GoBackBackButton from "./BackButton";
import { NavigationContainer } from "../../Styles/styledElements";

const MainNavigation = () => {
  return (
    <NavigationContainer>
      <GoBackBackButton />
    </NavigationContainer>
  );
};

export default MainNavigation;
