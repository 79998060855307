import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
//import { useStyles } from "../Styles/muiStyles";
import {
  SearchNavContainer,
  SearchContainerAll,
  SearchIconContainer,
} from "../Styles/styledElements";
import axios from "axios";
import SearchItems from "./SearchComponents/SearchItems";
import { TextField } from "@material-ui/core";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const Search = ({ focusHandler, activeSearchBar }) => {
  //const classes = useStyles();
  const theme = useContext(ThemeContext);
  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const clearText = () => {
    setText("");
  };

  useEffect(() => {
    axios
      .get(
        `https://${process.env.REACT_APP_HUB_URL}/api/v3/search?query=${text}&limit=10&apikey=${process.env.REACT_APP_FM_CLIENT_ID}`
      )
      .then((response) => {
        setSearchResults(response.data);
      });
  }, [text]);

  return (
    <SearchNavContainer>
      <SearchContainerAll>
        <TextField
          disabled={false}
          type="text"
          name="text"
          placeholder="Search..."
          label={
            <SearchIconContainer>
              <AiOutlineSearch />
            </SearchIconContainer>
          }
          onChange={handleTextChange}
          onFocus={() => focusHandler()}
          value={text}
          InputLabelProps={{
            style: {
              color: theme.name === "dark" ? "#d4af37" : "#146175",
              fontStyle: text.length ? "normal" : "oblique",
              borderColor: "#fff",
            },
          }}
          InputProps={{
            style: {
              color: theme.name === "dark" ? "#d4af37" : "#146175",
              fontStyle: "normal",
            },
          }}
          variant="outlined"
          size="small"
          style={{ width: "150px" }}
        />
        {text && activeSearchBar ? (
          <SearchItems searchResults={searchResults} clearText={clearText} />
        ) : (
          ""
        )}
        {text.length ? (
          <FaTimes style={{ padding: "4% 2%" }} onClick={clearText} />
        ) : (
          ""
        )}
      </SearchContainerAll>
    </SearchNavContainer>
  );
};

export default Search;
