export function usersWatchlist(user) {
  return `${process.env.REACT_APP_SERVER_ROUTE}/watchlist/userwatchlist/${user}`;
}

export function allComments() {
  return `${process.env.REACT_APP_SERVER_ROUTE}/comments/all`;
}

export function commentsBySymbol(symbol) {
  return `${process.env.REACT_APP_SERVER_ROUTE}/comments/${symbol}`;
}

export function sectorStocks(symbol) {
  return `https://api.tdameritrade.com/v1/marketdata/chains?apikey=${process.env.REACT_APP_GITHUB_CLIENT_ID}&symbol=${symbol}&contractType=ALL&strikeCount=2&includeQuotes=TRUE&toDate=${process.env.REACT_APP_DATE}range=OTM`;
}
