import styled from "styled-components";
//import { Button } from "@material-ui/core";
//import { mobileWidth } from "./ScreenBreakpoints";

//mui components

export const StyledButtonContainer = styled.div`
  margin-left: 2%;
  display: inline-flex;
`;
