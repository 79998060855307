import React from "react";
import { useRecoilState } from "recoil";
import {
  rightDrawerOpenState,
  rightDrawerContentState,
} from "../../recoil/atoms/globalState";
import PropTypes from "prop-types";
import { StyledClose } from "../Styles/styledElements";
import { overrideColor } from "../Styles/Overrides";
import { GrFormClose } from "react-icons/gr";

import "./RightDrawer.css";

const SlideDrawer = ({ isData, theme }) => {
  const [rightDrawerOpen, setRightDrawerOpen] =
    useRecoilState(rightDrawerOpenState);
  const [rightDrawerContent, setRightDrawerContent] = useRecoilState(
    rightDrawerContentState
  );

  const close = () => {
    setRightDrawerOpen(false);
    setRightDrawerContent("");
  };

  let rightDrawerClasses = "right-drawer";
  if (rightDrawerOpen) {
    rightDrawerClasses = "right-drawer open";
  }

  const content = rightDrawerContent ? rightDrawerContent : <p>error retreiving data</p>;

  return (
    <div
      className={rightDrawerClasses}
      style={{
        background: overrideColor(theme, "drawerBackground"),
        color: overrideColor(theme, "accentColor"),
      }}
    >
      <StyledClose>
        <GrFormClose
          onClick={() => {
            close();
          }}
        />
      </StyledClose>

      {content}
    </div>
  );
};

SlideDrawer.propTypes = {
  isData: PropTypes.bool,
  theme: PropTypes.string,
};

export default SlideDrawer;
