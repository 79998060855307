import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  rightDrawerOpenState,
  rightDrawerContentState,
} from "../../../recoil/atoms/globalState";
import axios from "axios";

import {
  SearchResultContainer,
  SearchResultItem,
  StyledSymbolLink,
  RightDrawerContentContainer,
  CommonH3,
  CommonH4,
  CommonDiv,
  CommonP,
} from "../../Styles/styledElements";
import KebabMenu from "../../Styles/MuiComponents/KebabMenu";
import CommentsCard from "../../Comments/CommentsCard";
import { sliceString } from "../../Globals/globalFunctions";
import { commentsBySymbol } from "../../Api/Urls";

const showableNameLength = 17;
const optionChainLink = (symbol) => {
  return `View ${symbol} option chain`;
};

const researchLink = (symbol) => {
  return `Research ${symbol}`;
};

const SearchItems = ({ searchResults, clearText }) => {
  const [rightDrawerOpen, setRightDrawerOpen] =
    useRecoilState(rightDrawerOpenState);

  const toggleRightDrawer = () => setRightDrawerOpen(!rightDrawerOpen);

  const setRightDrawerContentState = useSetRecoilState(rightDrawerContentState);

  const createRightDrawerContent = (sym) => {
    axios.get(commentsBySymbol(sym)).then((response) => {
      const comments = response.data.rows;

      console.log("comments", comments);

      console.log("comments", comments.length);
      setRightDrawerContentState(
        comments.length > 0 ? (
          <RightDrawerContentContainer>
            <CommonH3>{sym} Comments</CommonH3>
            {comments?.map((comment) => (
              <CommentsCard comment={comment} />
            ))}
          </RightDrawerContentContainer>
        ) : (
          <RightDrawerContentContainer>
            <CommonH4>No {sym} comments available</CommonH4>
            <CommonP>You can add one below</CommonP>
          </RightDrawerContentContainer>
        )
      );
    });
  };

  return (
    <SearchResultContainer>
      {searchResults.map((item) => (
        <SearchResultItem>
          {item?.symbol} {sliceString(item.name, showableNameLength)}
          <KebabMenu
            options={[
              <StyledSymbolLink
                to={`/chain/${item.symbol}`}
                onClick={clearText}
              >
                {optionChainLink(item.symbol)}
              </StyledSymbolLink>,
              <StyledSymbolLink to={`/research`} onClick={clearText}>
                {researchLink(item.symbol)}
              </StyledSymbolLink>,
              <CommonDiv
                onClick={() => {
                  toggleRightDrawer();
                  createRightDrawerContent(item.symbol);
                }}
              >
                View {item.symbol} comments
              </CommonDiv>,
            ]}
          />
        </SearchResultItem>
      ))}
    </SearchResultContainer>
  );
};

export default SearchItems;
