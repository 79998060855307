import React from "react";
import styled from "styled-components";
import { Card } from "@material-ui/core";
import PropTypes from "prop-types";


const StyledCard = styled(Card)`
  color: ${(props) => props.theme.cardColor};
  background-color: ${(props) => props.theme.cardBackground};
  box-shadow: ${(props) => props.theme.boxShadowColor};
  border-color: ${(props) => props.theme.accentColor};
  position: center;
  justify-items: center;
  z-index: 0;
  margin: 3% 3%;
  padding: 0.5% 1%;
  font-family: Avenir;
  font-weight: 500;
  border-radius: 15px;
`;

const MuiCard = ({ key, content, hidden }) => {
  return (
    <StyledCard key={key} hidden={hidden} variant="outlined" raised={true}>
      {content}
    </StyledCard>
  );
};

MuiCard.propTypes = {
  key: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  hidden: PropTypes.bool,
};

export default MuiCard;
